import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { Modal, Pagination, Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Empty from '@/components/record/empty';
import SvgIcon from '@/components/SvgIcon';
import { downloadFile } from '@/utils';
import { deleteLog, downloadLog, getLogPage } from '@/api/subtitles';
import styles from '@/components/style/index.module.less';
import modalStyles from '@/components/style/modal.module.less';

function Log() {
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(10);

  const { t } = useTranslation();

  const getLogPageRequest = useQuery({
    queryKey: ['getLogPage', curr, size],
    queryFn: () => getLogPage(curr, size).then((res) => {
      if (res.data.data.total) {
        setTotal(res.data.data.total);
      }

      return res;
    }),
  });

  const downloadLogRequest = (item: API.LogListItem) => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return downloadLog({
      user_subtitle_segment_id: item.user_subtitle_segment_id,
      zone,
    }).then((res) => {
      if (res.data.data) {
        const date = dayjs(item.gmt_create).format('YYYY-MM-DD');
        downloadFile(res.data.data, `${item.title}-${date}.txt`);
      }
    });
  };

  const onDelete = (id: string) => {
    Modal.confirm({
      className: modalStyles.modal,
      title: t('removeSubtitle'),
      icon: <SvgIcon className={modalStyles.icon} name="danger-solid" />,
      content: (
        <p>{t('removeSubtitleTips')}</p>
      ),
      onOk: () => deleteLog(id).then(() => {
        getLogPageRequest.refetch();
      }),
    });
  };

  const onChange = (page: number, pageSize: number) => {
    setCurr(page);
    setSize(pageSize);
  };
  return (
    <div className={styles.pageContent}>
      <div className={styles.pageTitle}>{t('translationRecord')}</div>
      <div className={styles.translatorlog}>
        {getLogPageRequest.isLoading && <Spin className="!block mx-auto" size="large" />}
        {getLogPageRequest.data?.data.data.records.length === 0 && <Empty text={t('noRecord')}/>}
        {getLogPageRequest.data?.data.data.records.map((item) => (
          <div key={item.user_subtitle_segment_id} className={styles.item}>
            <h1>
              <Link className={styles.itemTitle} to={`/record/${item.user_subtitle_segment_id}`} state={{ from: 'log' }}>{item.title}</Link>
              {/*
              <Link className={styles.itemTitle} to={`/record/analyse/${item.user_subtitle_segment_id}`} state={{ from: 'log' }}>{item.title}</Link>
               */}
            </h1>
            <div className={styles.desc}>
              <em className={clsx(styles.from, styles[item.source.toLocaleLowerCase().replace('_', '-')])}>
                {item.source.replace('_', ' ')}
              </em>
              <a className={styles.url} href={item?.source_url} target="_blank">{item?.source_url}</a>
            </div>
            <p className={styles.content}>
              <Link to={`/record/${item.user_subtitle_segment_id}`} state={{ from: 'log' }}>
                {item.summary}
              </Link></p>
            <time className={styles.time}>
              {dayjs(item.gmt_create).format('YYYY-MM-DD HH:mm:ss')}
            </time>
            <div className={styles.action}>
              <Tooltip placement="bottom" title={t('exportSubtitle')}>
                <SvgIcon name="download" onClick={() => downloadLogRequest(item)}/>
              </Tooltip>
              <Tooltip placement="bottom" title={t('removeSubtitle')}>
                <SvgIcon name="delete" onClick={() => onDelete(item.user_subtitle_segment_id)} />
              </Tooltip>
            </div>
          </div>
        ))}
        <Pagination className={styles.paginationRecord} pageSize={size}showSizeChanger={false} current={curr} total={total} hideOnSinglePage onChange={onChange} />
      </div>
    </div>
  );
}

export default Log;
